/**
* Generated automatically at built-time (2025-04-04T11:48:30.141Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem, isPathfinderAware, mangleTemplateProps } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
const templates=props.templates.map(template=>{if (!isPathfinderAware(template.component, template.props)) {return template;} return {...template,props: {...template.props, ...mangleTemplateProps(template.component, template.props, props.pathfinder)}}})
return mergeTemplateItems(defaultTemplate, templates).map(createLazyTemplateItem);
}
Template.mapValueToProps = value => ({ pathfinder: value.pathfinder });
Template.mapValueToTemplateProps = {pageKey: "liebherr",templateKey: "sites/86-14aae1b9-5622-42f2-bd07-a78ecf9b666a"};